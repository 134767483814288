import React from "react";
import MainBanner from "../MainBanner/MainBanner";

class Home extends React.Component {
    render() {
        return (
            <div className="home">
                <MainBanner></MainBanner>
            </div>
        );
    }
}

export default Home;